<template>
  <div>
    <v-data-table
      :fixed-header="false"
      :height="undefined"
      v-bind="dataTableAttrs"
      :headers="headers"
      :items="list"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page.sync="itemsPerPage"
      v-model="selectedItems"
      @update:items-per-page="handleItemsPerPageUpdated"
    >
      <template v-slot:top>
        <rs-table-title-bar
          v-if="$refs.headSearch"
          title="Notlar ve Hatırlatmalar"
          icon=""
          @click:add.prevent="handleAddClick"
          @clear-filters="$refs.headSearch.reset"
          @reload="loadList"
          @click:edit="handleEditClick"
          @click:delete="handleDeleteClick"
          :edit-enabled="allowAdd && selectedItems.length === 1"
          :show-delete="allowAdd"
          :delete-enabled="selectedItems.length === 1"
          :search.sync="search.query"
        >
          <template slot="buttons">
            <v-btn
              tile
              color="indigo"
              dark
              small
              v-if="allowAdd && can('edit-note')"
              class="float-end ms-2"
              @click.prevent="handleAddClick({ type: 1 })"
            >
              Not Ekle
            </v-btn>

            <v-btn
              tile
              color="indigo"
              dark
              small
              v-if="allowAdd && can('edit-note')"
              class="float-end ms-2"
              @click.prevent="handleAddClick({ type: 2 })"
            >
              Görüşme Kaydı Ekle
            </v-btn>
          </template>
        </rs-table-title-bar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:body.prepend="{ headers }">
        <rs-table-head-search
          :headers="headers"
          :search.sync="search"
          ref="headSearch"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.edit="{ item }" v-if="allowAdd">
        <v-btn text icon @click="handleEditClick(item)">
          <i class="menu-icon mdi mdi-pencil"></i>
        </v-btn>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.subject="{ item }">
        {{ item.subject }}
        <v-icon v-if="item.document_count > 0" title="Ekli belge mevcut">
          mdi-paperclip
        </v-icon>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.created_at="{ item }">
        {{ new Date(item.created_at).toLocaleString() }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.has_reminder="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>
    </v-data-table>
    <NoteForm
      v-if="allowAdd"
      :extra-params="extraParams"
      @dismissed="handleFormDismiss"
      @saved="handleFormSaved"
      ref="noteForm"
    />

    <rs-confirm
      ref="confirmDelete"
      :loading="isLoading"
      @confirmed="handleDelete"
      @cancelled="$refs.confirmDelete.hide()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasPermissions } from "@/view/mixins";
import NoteForm from "@/view/content/Notes/NoteForm";

export default {
  components: {
    NoteForm,
  },
  mixins: [hasDataTable, hasPermissions],
  props: {
    remindersOnly: {
      default: false,
      required: false,
      type: Boolean,
    },
    allowAdd: {
      default: false,
      required: false,
      type: Boolean,
    },
    extraParams: {
      default: () => {},
      required: true,
      type: Object,
    },
    itemCount: {
      required: false,
      type: Number,
      default: 0,
    },
    formSubtitle: {
      required: false,
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["noteTypeList"]),
  },
  data() {
    return {
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Konu",
          value: "subject",
          searchable: "text",
        },
        {
          text: "Not Tipi",
          value: "note_type",
          searchable: "select",
          options: () => this.noteTypeList,
          itemText: "name",
        },
        {
          text: "Not Tarihi",
          value: "created_at",
          searchable: "date",
        },
        {
          text: "Hatırlatma",
          value: "has_reminder",
          searchable: "isActive",
          align: "center",
        },
      ],
    };
  },
  created() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  watch: {
    extraParams: {
      handler() {
        // triggers loading the list
        this.options.page = 1;
      },
      deep: true,
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
  },
  methods: {
    loadList() {
      this.selectedItems = [];
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options),
        this.extraParams
      );

      if (this.remindersOnly) {
        params.reminders_only = 1;
      }

      this.$api
        .query("notes", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.$emit("update:item-count", this.total);
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleAddClick({ type }) {
      this.$refs.noteForm.show(null, null, this.formSubtitle || null);
      this.$nextTick(() => {
        this.$refs.noteForm.setType(type);
      });
    },
    handleEditClick(item) {
      if (!item.id) {
        item = this.selectedItems[0];
      }

      this.$refs.noteForm.show(
        item.id,
        !this.can("edit-note"),
        this.formSubtitle || null
      );
    },
    handleFormSaved() {
      this.options.page = 1;
      this.loadList();
    },
    handleFormDismiss() {},
    handleDelete() {
      this.isLoading = true;

      this.$api
        .delete(`notes/${this.selectedItems[0].id}`)
        .then(() => {
          this.$toast.success("Silindi");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$nextTick(() => this.loadList());
        });
      this.$refs.confirmDelete.hide();
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show("Silmek istediğinizden emin misiniz?");
    },
  },
};
</script>
